import { Home } from "@/components/Home";
import { getFeaturedProducts } from "@/lib/prismicio/featuredProducts";
import { fetchHome } from "@/lib/prismicio/home";
import { getPage } from "@/lib/ssg";
import { useWishlist } from "@/lib/store/wishlist";
import { FeaturedProductsContext } from "@jog/react-components";
import { fetchS3HomePage, getDomains, lowerCaseEq, lowerCaseLocale, retry } from "@jog/shared";
import { LogoLoader } from "components";
import { staticPage } from "lib";
import Layout from "../layout";
export async function getStaticProps(context) {
    return staticPage(context, async () => {
        const prismicContent = await retry(() => context.preview && context.previewData
            ? fetchHome(context.previewData)
            : fetchS3HomePage());
        const products = (await getFeaturedProducts(prismicContent, getPage)) || [];
        const paths = getDomains()
            .map(({ domain, locale }) => ({ url: domain.replace(/\/$/, ""), locale }))
            .filter(({ locale }) => !lowerCaseEq(locale, lowerCaseLocale()));
        return { prismicContent, products, paths };
    });
}
export default function IndexPage({ data: { prismicContent, products, paths }, }) {
    const actionLoading = useWishlist.use.actionPending();
    return (<Layout gtmTypeOfPage="home">
            <FeaturedProductsContext.Provider value={{ products }}>
                {actionLoading && <LogoLoader />}
                <Home prismic={prismicContent.data} paths={paths}/>
            </FeaturedProductsContext.Provider>
        </Layout>);
}
